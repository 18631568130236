import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { selectUserIsAuthenticated, useQueryPopup } from '@mwl/core-lib';

import { Popup } from '@/components/Popup/Popup';
import { useTypedSelector } from '@/hooks';

import { FormRecovery } from '../FormRecovery/FormRecovery';

const BasePopupRecovery: FC = () => {
  const { show, onClose } = useQueryPopup('recovery');

  const isAuth = useTypedSelector(selectUserIsAuthenticated);

  const handleClose = useCallback(() => onClose(), [onClose]);

  useEffect(() => {
    if (isAuth && show) {
      onClose({ shallow: false });
    }
  }, [show, isAuth, onClose]);

  return (
    <Popup show={show && !isAuth} onClose={handleClose}>
      <FormRecovery defaultTab="link" />
    </Popup>
  );
};

const PopupRecovery = memo(BasePopupRecovery);
export { PopupRecovery };
