import type { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { checkUserAuthOnServer, loadBanners, usePasswordlessLogin } from '@mwl/core-lib';

import { PopupRecovery } from '@/components/PopupRecovery/PopupRecovery';
import { defaultLocale, routes } from '@/constants';
import type { CasinoLayoutProps } from '@/Layouts/CasinoLayout/CasinoLayout.types';
import { wrapperStore } from '@/store/store';
import { getNamespaces } from '@/utils';

const CasinoLayout = dynamic(() => import('@/Layouts/CasinoLayout/CasinoLayout').then((mod) => mod.CasinoLayout));
const IndexPage = dynamic(() => import('@/modules/main/MainPage').then((mod) => mod.MainPage));

export interface CasinoLayoutServerProps extends Pick<CasinoLayoutProps, 'banners'> {}

const Home: NextPage<CasinoLayoutServerProps> = ({ banners }) => {
  usePasswordlessLogin({
    profileUrl: routes.profile.home,
  });

  return (
    <>
      <PopupRecovery />
      <CasinoLayout navigation={false} banners={banners}>
        <IndexPage />
      </CasinoLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<CasinoLayoutServerProps> = wrapperStore.getServerSideProps(
  ({ dispatch }) =>
    async ({ locale = defaultLocale, req, res }) => {
      const { localServerAxiosInstance } = await checkUserAuthOnServer({ dispatch, locale, res, req });

      const [translations, banners] = await Promise.all([
        serverSideTranslations(locale, getNamespaces('casino')),
        loadBanners('main_slider', localServerAxiosInstance),
      ]);

      return {
        props: {
          ...translations,
          ...banners,
        },
      };
    },
);

export default Home;
